import { Component, computed, inject, input, OnInit, signal } from '@angular/core';
import { catchError, debounceTime, distinctUntilChanged, filter, forkJoin, Observable, of, Subject, switchMap } from 'rxjs';
import * as UiDropdwon from '@components/ui/ui-dropdown'
import { ApiService } from '@services/api-service.service';
import { CommonModule } from '@angular/common';
import { IUserUserApp } from '@models/users';
import { ISubjectGroup } from '@models/subject-group';
import { IProfessorToSubjectGroup } from '@models/professorToSubjectGroup';
import { UiInputComponent } from '@components/ui/ui-input/ui-input.component';
import { UiBadgeComponent } from '@components/ui/ui-badge/ui-badge.component';
import { UiButtonComponent } from '@components/ui/ui-button/ui-button.component';

interface UserItem { value: number | string; label: string }
@Component({
  selector: 'assign-users',
  standalone: true,
  imports: [
    CommonModule,
    UiDropdwon.Root,
    UiDropdwon.Trigger,
    UiDropdwon.Content,
    UiDropdwon.Item,

    UiInputComponent,
    UiBadgeComponent,
    UiButtonComponent
  ],
  templateUrl: './assign-users.component.html',
  styleUrl: './assign-users.component.css'
})
export class AssignUsersComponent implements OnInit {
  subjectGroup = input.required<ISubjectGroup>();
  private _oldProfessors = signal<IUserUserApp[]>([]);
  /* private _usersToAdd = signal<UserItem[]>([]); */
  private _apiService = inject(ApiService);
  private _searchSubject = new Subject<string>();
  public oldProfessorIds = computed<number[]>(() => this._oldProfessors().map(e => e.id!));
  public userList = signal<(IUserUserApp & {disabled?:boolean})[]>([]); 
  public loadingData = signal<boolean>(false); 
  public addingUsers = signal<boolean>(false); 
  public userBadges = signal<IUserUserApp[]>([]);

  ngOnInit(): void {
    const ptsg = this.subjectGroup().ProfessorToSubjectGroups
      .filter(professor => professor?.professor)
      .map(e => e.professor!);

    const users:IUserUserApp[] = [
      this.subjectGroup().userapp, 
      ...ptsg
    ].filter(e => e !== undefined) as IUserUserApp[]
  
    this._oldProfessors.set(users);

    /* -------- */

    /* this._usersToAdd.set(this._oldProfessors()
      .map(e => ({ value: e.id!, label: `${e.Name1} ${e.LastName1}` }))); */
    this.userBadges.set(this._oldProfessors());

    /* -------- */

    this._searchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter((value) => value.length > 3),
      switchMap(value => this.findUsers(value))
    ).subscribe({
      next: (resp:IUserUserApp[]) => {
        this.userList.set(resp.map(e => ({...e, disabled:this.userBadges().some(user => user.id === e.id!)})));
      }, error: (err) => {
        console.log(err);
      }, complete: () => console.log('done')
    })
  }

  public assignProfessors(event:UserItem){
    //this._usersToAdd.update(prev => [...prev, event]);
    this.userBadges.update(prev => {
      const user = this.userList().find(e => e.id === event.value);
      if(!user) return prev;

      return [...prev, user]
    })

    this.toggleUser(true, Number(event.value));
  }

  public removeProffesor(id:number){
    //this._usersToAdd.set(this._usersToAdd().filter(e => e.value !== id));
    this.userBadges.update(prev => prev.filter(e => e.id !== id));
    this.toggleUser(false, id);
  }

  private toggleUser(disable:boolean, id:number){
    this.userList().forEach(e => {
      if(e.id === id){
        e.disabled = disable;
        return;
      }
    })
  }

  public saveAll(){
    const ptsgToDeleteObs = this.userBadges().map(user => this.deleteProfessors(user.id!));
    const toCreateObs = this.userBadges().map(user => this.createProfessors({
      userID: Number(user.id),
      subjectGroupID: this.subjectGroup().id!,
      subjectGroupOfferID: this.subjectGroup().CourseOferID
    }).pipe(catchError(_ => of(null))));

    this.addingUsers.set(true);
    this.patchProfessor({ ...this.subjectGroup(), UserID: Number(this.userBadges()[0].id) })
      .pipe(
        switchMap(_ => forkJoin(ptsgToDeleteObs)),
        switchMap(_ => this.userBadges().length > 1 ? forkJoin(toCreateObs.slice(1)) : of(null))
      )
      .subscribe({
      next: (result) => {
        console.log(result);
      }, error: (err => console.log(err)),
      complete: () => {
        this.addingUsers.set(false);
      }
    })
  }

  public onSearchChange(event:Event){
    const value = (event.target as HTMLInputElement).value;
    this._searchSubject.next(value);
  }

  private findUsers(value: string): Observable<IUserUserApp[]> {
    const params = {
      path: 'ViewUserInfos',
      filter: {
        where: {
          or: [{ CedocEmail: { regexp: `/${value}/i` } }, { Document: { regexp: `/${value}/i` } }]
        },
        limit: 10
      }
    }
    return this._apiService.get<IUserUserApp>(params)
  }

  private createProfessors(data:IProfessorToSubjectGroup): Observable<IProfessorToSubjectGroup>{
    const params = {
      path:'ProfessorToSubjectGroups',
      data,
    }
    
    return this._apiService.post<IProfessorToSubjectGroup>(params)
  }

  private patchProfessor(data:ISubjectGroup): Observable<ISubjectGroup>{
    const params = {
      path:'SubjectGroups',
      data,
    }

    return this._apiService.patch<ISubjectGroup>(params)
  }

  private deleteProfessors(ptsgID:number): Observable<IProfessorToSubjectGroup | null>{
    const params = {
      path:`ProfessorToSubjectGroups/${ptsgID}`,
    }

    return this._apiService.delete<IProfessorToSubjectGroup>(params).pipe(
      catchError(error => of(null))
    )
  }
}