import { HttpInterceptorFn } from '@angular/common/http';

export const requestHandlerInterceptor: HttpInterceptorFn = (req, next) => {
  const token = localStorage.getItem('accessToken') || '';

  const authReq = req.clone({
    setHeaders: {
      'Content-Type': 'application/json',
      Authorization: token,
    }
  });

  return next(authReq);
};
