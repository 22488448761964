<div class="assign-users" [ngClass]="{'assign-users--disabled': addingUsers()}">
  <div class="assign-users__users">
    @for (item of userBadges(); track $index) {
    <ui-badge>
      <img [src]="item.photo || item.Photo" alt="avatar" class="avatar">
      {{item.Name1}} {{item.LastName1}}
      <em class="icon-i-close close-icon" (click)="removeProffesor(item.id!)"></em>
    </ui-badge>
    }
  </div>
  <ui-dropdown [closeOnSelect]="false" (onValueChange)="assignProfessors($event)">
    <ui-dropdown-trigger>
      <ui-input variant="bordered" (keyup)="onSearchChange($event)" placeholder="Seleccionar profesores"></ui-input>
    </ui-dropdown-trigger>
    <ui-dropdown-content>
      @if(userList().length > 0){
        @for (item of userList(); track $index) {
          <ui-dropdown-item [value]="item.id!" [disabled]="!!item.disabled">{{item.Name1}} {{item.LastName1}}</ui-dropdown-item>
        }
      }@else {
      <div>Sin resultados.</div>
      }
    </ui-dropdown-content>
  </ui-dropdown>
  <ui-button color="primary" [disabled]="userBadges().length === 0 || addingUsers()" (onClick)="saveAll()">Guardar</ui-button>
</div>