import { DatePipe, TitleCasePipe } from '@angular/common';
import { Component, OnDestroy, OnInit,TemplateRef,ViewChild,computed, inject, input, signal } from '@angular/core';
import { UiDropdownContentComponent } from '@components/ui/ui-dropdown/ui-dropdown-content/ui-dropdown-content.component';
import { UiDropdownItemComponent } from '@components/ui/ui-dropdown/ui-dropdown-item/ui-dropdown-item.component';
import { UiDropdownTriggerComponent } from '@components/ui/ui-dropdown/ui-dropdown-trigger/ui-dropdown-trigger.component';
import { UiDropdownComponent } from '@components/ui/ui-dropdown/ui-dropdown.component';
import { UiInputComponent } from '@components/ui/ui-input/ui-input.component';
import { IEvent } from '@models/events';
import { IOffers } from '@models/offers';
import { ISchool } from '@models/school';
import { IUser } from '@models/users';
import { ApiService } from '@services/api-service.service';
import { SweetalertService } from '@services/sweetalert.service';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarModule, CalendarView, DAYS_OF_WEEK} from 'angular-calendar';
import { endOfDay, isSameDay, isSameMonth } from 'date-fns';
import { startOfDay } from 'date-fns/startOfDay';
import { Observable, Subject, debounceTime, filter, forkJoin, switchMap, take, takeUntil } from 'rxjs';
import { EventColor } from 'calendar-utils';
import moment from 'moment';
import { colors } from './colors';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';

registerLocaleData(localeEs);
@Component({
  selector: 'app-schedule-table',
  standalone: true,
  imports: [UiDropdownComponent,UiDropdownTriggerComponent,UiDropdownItemComponent,UiDropdownContentComponent,UiInputComponent,TitleCasePipe,CalendarModule,DatePipe],
  templateUrl: './schedule-table.component.html',
  styleUrl: './schedule-table.component.css'
})
export class ScheduleTableComponent implements OnInit,OnDestroy {
  editableInfo = input<IEvent | null>();
  
  public weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  public weekendDays: number[] = [ DAYS_OF_WEEK.FRIDAY, DAYS_OF_WEEK.SATURDAY ];
  public locale: string = 'es';

  public view = CalendarView.Week;
  @ViewChild('schoolSelector') schoolSelector: any;
  @ViewChild('offerSelector') offerSelector: any;

  @ViewChild('modalContent', { static: true })modalContent!: TemplateRef<any>;
  activeDayIsOpen: boolean = true;
  listView: boolean = true;
  viewDate: Date = new Date();
  modalData!: {
    action: string;
    event: CalendarEvent;
  };
  scheduleList: any[] = [];
  schoolList: any[] = [];
  offerList: any[] = [];
  selectedSchoolId: number = 0;
  selectedSchoolsList: any;
  selectedOfferList: any;
  selectedCourseId: number = 0;

  private _apiService = inject(ApiService);
  private _sweetalertService = inject(SweetalertService);

  public mainSchools = computed<ISchool[]>(() => this._apiService.schools());
  public _schoolsFiltered= signal<ISchool[]>([]);
  public _schoolsSubject: Subject<string> = new Subject();

  private _filteredGuests = signal<IUser[]>([]);
  private _selectedGuests = signal<(IUser & { eventUserId?: number })[]>([]);
  private _guestSubject$: Subject<string> = new Subject();


  //Ofertas revisar el tipo
  public mainOffers: any[] = [];
  public _offersFiltered= signal<IOffers[]>([]);
  public _offersSubject: Subject<string> = new Subject();

  private _destroy$: Subject<void> = new Subject();
  public loadingGuest = signal<boolean>(false);

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  ngOnInit(): void {
    this.getAllChildrenSchools();
    this.getScheduleSubject();
    this._guestSubject$
    .pipe(
      takeUntil(this._destroy$),
      filter((value) => value.length > 3),
      debounceTime(300),
      switchMap((value) => this.guestQry(value)),
    ).subscribe((guests: IUser[]) => {
      this._filteredGuests.set(guests);
    });

    this._schoolsSubject
    .pipe(
      takeUntil(this._destroy$),
      filter((value) => value.length > 3),
      debounceTime(300),
      switchMap((value) => this.schoolQry(value)),
    ).subscribe((schools: ISchool[]) => {
      this._schoolsFiltered.set(schools);
    });

    //Ofertas
    this._offersSubject
    .pipe(
      takeUntil(this._destroy$),
      filter((value) => value.length > 3),
      debounceTime(300),
      switchMap((value) => this.offersQry(value)),
    ).subscribe((offers: IOffers[]) => {
      this._offersFiltered.set(offers);
    });
  }

  private guestQry(value: string): Observable<IUser[]> {
    const fetchParams = {
      path: 'ViewUserInfos',
      filter: {
        where: {
          or: [
            { email: { regexp: `/${value}/i` } },
            { Document: { regexp: `/${value}/i` } },
          ],
        },
        limit: 4,
      },
    };

    return this._apiService
      .get<IUser>(fetchParams)
      .pipe(takeUntil(this._destroy$));
  }

  private schoolQry(value: string): Observable<ISchool[]> {
    const fetchParams = {
      path: 'Schools',
      filter: {
        where: {
          or: [
            { NameTSchool: { regexp: `/${value}/i` } },
          ],
        },
        limit: 4,
      },
    };

    return this._apiService
      .get<ISchool>(fetchParams)
      .pipe(takeUntil(this._destroy$));
  }

  private offersQry(value: string): Observable<IOffers[]> {
    const fetchParams = {
      path: 'CoursesOfers',
      filter: {
        where: {
          or: [
            { NameCourseOfer: { regexp: `/${value}/i` } },
          ],
        },
        limit: 4,
      },
    };

    return this._apiService
      .get<IOffers>(fetchParams)
      .pipe(takeUntil(this._destroy$));
  }

  public findGuest(event: Event) {
    const value = (event.target as HTMLInputElement).value;
    this._guestSubject$.next(value);
  }

  public findSchool(event: string | number) {
    this._schoolsSubject.next(event.toString());
  }

  public findOffer(event: string | number) {
    this._offersSubject.next(event.toString());
  }

  public selectGuest(value: string) {
    const getUserInfo = this._filteredGuests().find((e) => e.id === Number(value));
    let usersToAdd = [...this._selectedGuests()];

    const alreadyAdded = this._selectedGuests().some(
      (e) => e.id === Number(value)
    );

    if (!alreadyAdded && getUserInfo) 
      usersToAdd.push(getUserInfo);

    this._selectedGuests.set(usersToAdd);
    this.getScheduleSubject();
  }

  public guests = computed<{ name: string; id: number }[]>(() => {
    return this._filteredGuests().map((e) => ({
      name: this.clearName(e),
      id: e.id!,
    }));
  });


  public removeGuest(userId: number) {
    const getUser = this._selectedGuests().filter((e) => e.id !== userId);
    this._selectedGuests.set(getUser);
  }

  public selectedGuests = computed<
    {
      name: string;
      id: number;
      email: string;
      eventUserId: number | undefined;
    }[]
    >(() => {
      return this._selectedGuests().map((e) => ({
        eventUserId: e.eventUserId,
        name: this.clearName(e),
        email: e.email,
        id: e.id!,
      }));
    });


  private clearName(user: IUser) {
    return `${user.Name1} ${user.Name2 || ''} ${user.LastName1} ${
      user.LastName2 || ''
    }`.replace(/\s+/g, ' ');
  }


  getAllChildrenSchools(): void{
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');

    forkJoin({
      MY_SCHOOL_LIST: this._apiService.get({path:"Schools"}).pipe(take(1)),
    }).subscribe({
      next: (data:any) => {
        let {MY_SCHOOL_LIST} = data;
        this.schoolList = MY_SCHOOL_LIST;
        this._sweetalertService.swalClose();
      },
      error: (err) => {
        console.log("ERROR getAllInitialDataReqResume",err);
        this._sweetalertService.swalError('Error','No se pudo cargar la información necesaria, intentelo nuevamente, si el error persiste, contacte a soporte.', ()=>{});
      },
      complete: () => {
        console.log('complete getAllInitialDataReqResume');
      }
    });
  }

  onCourseChange(event: any) {
    this.selectedCourseId = event.target.value;

    this.selectedOfferList = this.offerList.find((e) => e.id == this.selectedCourseId);


    this.selectedOfferList = this.selectedOfferList.NameCourseOfer;


    this.getScheduleSubject();
  }

  getScheduleSubject(): void{
    let ids: number[] = [];
    ids = this.selectedGuests().map(item => item.id);
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');
    forkJoin({
      MY_SCHEDULE_LIST: this._apiService.get({path:"SubjectGroups",
        filter:{
          //Cambiar aqui el courseOfferId
          where:{and:[
            {CourseOferID:this.selectedCourseId},
            {UserID:{inq:[...ids]}}
          ]},
          include: ["userapp","ProfessorToSubjectGroups","scheduleSubjects","subjects","coursesgroup"],

      }}).pipe(take(1)),
    }).subscribe({
      next: (data:any) => {
        let {MY_SCHEDULE_LIST} = data;
        this._sweetalertService.swalClose();
        this.scheduleList = MY_SCHEDULE_LIST;
        this.processScheduleGroups(this.scheduleList);
      },
      error: (err) => {
        console.log("ERROR getAllInitialDataReqResume",err);
        this._sweetalertService.swalError('Error','No se pudo cargar la información necesaria, intentelo nuevamente, si el error persiste, contacte a soporte.', ()=>{});
      },
      complete: () => {
        console.log('complete getAllInitialDataReqResume');
      }
    });
  }

  //Ofertas
  getOfferData(): void{
    this._sweetalertService.swalLoading('Cargando','Por favor, espere');

    forkJoin({
      MY_COURSES_OFFER: this._apiService.get({path:"CoursesOfers",
        filter: {
          where: {SchoolID: this.selectedSchoolId}
        }
      }).pipe(take(1)),
    }).subscribe({
      next: (data:any) => {
        let {MY_COURSES_OFFER} = data;
        this.offerList = MY_COURSES_OFFER;
        this.mainOffers = MY_COURSES_OFFER;
        this._sweetalertService.swalClose();
      },
      error: (err) => {
        console.log("ERROR getAllInitialDataReqResume",err);
        this._sweetalertService.swalError('Error','No se pudo cargar la información necesaria, intentelo nuevamente, si el error persiste, contacte a soporte.', ()=>{});
      },
      complete: () => {
        console.log('complete getAllInitialDataReqResume');
      }
    });
  }


  refresh = new Subject<void>();
  events: CalendarEvent[] = [];
  
  processScheduleGroups(subjectGroups: any[]){
    this.events = [];
    subjectGroups.forEach( (subjectGroup, index)  =>{

      const scheduleSubject:any[] = subjectGroup.scheduleSubjects;
      const subject = subjectGroup.subjects;
      const courseGroup = subjectGroup.coursesgroup;

      scheduleSubject.forEach((schedule)=>{

        const event = {
          title: `${subject.NameSubject} - ${courseGroup.name}`,
          color: colors[ index % colors.length ],
          //moment().toDate() para colocar dia de hoy basicamente 
          start: moment(schedule.HourStart).toDate(),
          end: moment(schedule.HourEnd).toDate(),
          resizable: { beforeStart: false, afterEnd: false },
          draggable: false,
          meta: {
            scheduleSubjectID: schedule.id,
            subjectGroupID: subjectGroup.id,
            subject,
            courseGroup,
            user: subjectGroup.userapp,
          },
        };
        this.events.push(event);
        this.refresh.next();
      })
    })

  }

  onSchoolChange(event: any) {
    this.selectedSchoolId = event.target.value;

    this.selectedSchoolsList = this.schoolList.find((e) => e.id == this.selectedSchoolId);
    
    this.selectedSchoolsList = this.selectedSchoolsList.NameTSchool

    this.getOfferData();
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    // this.modal.open(this.modalContent, { size: 'lg' });
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New event',
        start: startOfDay(new Date()),
        end: endOfDay(new Date()),
        // color: colors.red
        draggable: true,
        resizable: {
          beforeStart: true,
          afterEnd: true,
        },
      },
    ];
  }

  public closeOpenMonthViewDay() {
    //
  }

  resetSchoolSelection(): void {
    this.schoolSelector.nativeElement.value = '';
    this.selectedSchoolsList = null;
    this.selectedSchoolId = 0;

    if(this.selectedOfferList){
      this.offerSelector.nativeElement.value = '';
      this.selectedOfferList = null;
      this.selectedCourseId = 0;
    }
    this.getScheduleSubject();
  }

  resetOfferSelection(): void {
    this.offerSelector.nativeElement.value = '';
    this.selectedOfferList = null;
    this.selectedCourseId = 0;
    this.getScheduleSubject();
    
  } 
  //Path para traer la data /SubjectGroups 
  
  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}

