import { CommonModule } from '@angular/common';
import { Component, computed, forwardRef, input, output, signal } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

interface IDay {
  id: string;
  value: string;
  checked: boolean;
}

@Component({
  selector: 'days-selector',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './days-selector.component.html',
  styleUrl: './days-selector.component.css',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DaysSelectorComponent),
      multi: true,
    },
  ],
})
export class DaysSelectorComponent implements ControlValueAccessor {
  onValueChange = output<string>();
  invalid = input<boolean>(false);

  public days = signal<IDay[]>([
    { id: 'Monday', value: 'L', checked: false },
    { id: 'Tuesday', value: 'M', checked: false },
    { id: 'Wednesday', value: 'M', checked: false },
    { id: 'Thursday', value: 'J', checked: false },
    { id: 'Friday', value: 'V', checked: false },
    { id: 'Saturday', value: 'S', checked: false },
    { id: 'Sunday', value: 'd', checked: false },
  ]);

  private _daysToSave = computed(() =>
    this.days()
      .filter(e => e.checked)
      .map(e => e.id)
  );

  //#region ACCESSOR
  private _onChange = signal<(_: any) => void>(_ => {});
  public onTouch = signal<() => void>(() => {});
  public isDisabled = signal<boolean>(false);
  //#endregion

  /**
   * On checkbox change
   * @param item 
   */
  public onChange(item: IDay) {
    const daysCopy = [...this.days()];
    const dayToUpdate = daysCopy.find(e => e.id === item.id);

    if (dayToUpdate) 
      dayToUpdate.checked = !dayToUpdate.checked;

    this.days.set(daysCopy);
    const result = this._daysToSave().length > 0 ? 
      JSON.stringify(this._daysToSave()) : ''

    this.onValueChange.emit(result);
    this._onChange.update(_ => {
      _(result);
      return _;
    });
  }

  //#region ACCESSORS
  writeValue(value: string): void {
    if (!value) return;

    const parsedValue: string[] = JSON.parse(value);
    const daysCopy = [...this.days()];
    daysCopy.forEach(day => {
      if(parsedValue.some(e => e === day.id))
        day.checked = true;
    })

    this.days.set(daysCopy);
  }

  registerOnChange(fn: any): void {
    this._onChange.set(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouch.set(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled.set(isDisabled);
  }
  //#endregion
}
