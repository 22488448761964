import { Injectable, signal } from '@angular/core';
import { Subject } from 'rxjs';
import { IDropdownItem } from '../models/dropdown-item';

@Injectable({
  providedIn: 'root'
})
export class UiDropdownService {
  constructor() { }

  public $onItemChange = new Subject<IDropdownItem>();
  public trigger = signal<boolean>(false);
  public useToggle = signal<boolean>(false);

  public setItem(item:IDropdownItem){
    this.$onItemChange.next(item);
  }
}
