import { Component, inject } from '@angular/core';
import { UiDropdownService } from '../ui-dropdown.service';

@Component({
  selector: 'ui-dropdown-trigger',
  templateUrl: './ui-dropdown-trigger.component.html',
  standalone: true
})
export class UiDropdownTriggerComponent {
  private _dropdownService = inject(UiDropdownService);

  /**
   * Open modal
   */
  public setOpen(): void {
    const useToggle = this._dropdownService.useToggle();
    this._dropdownService.trigger.set(!useToggle ? true : !this._dropdownService.trigger());
  }
}
