<section class="assign-teachers">
  <div class="school-selector">
    <div class="assign-school">
      <div class="assign-school__schools">
        @for (item of selectedSchooBadges(); track $index) {
        <ui-badge>
          <img [src]="item.Icono" alt="avatar" class="avatar">
          {{item.NameTSchool}}
          <em class="icon-i-close close-icon" (click)="removeSchoolBadge(item.id!)"></em>
        </ui-badge>
        }
      </div>
      <ui-dropdown [closeOnSelect]="false" (onValueChange)="onSelecteSchools($event)">
        <ui-dropdown-trigger>
          <ui-input variant="bordered" (keyup)="onSearchChange($event)" placeholder="Seleccionar escuela"></ui-input>
        </ui-dropdown-trigger>
        <ui-dropdown-content>
          @if(schoolList().length > 0){
            @for (item of schoolList(); track $index) {
              <ui-dropdown-item [value]="item.id!" [disabled]="!!item.disabled">{{item.NameTSchool}}</ui-dropdown-item>
            }
          }@else {
          <div>Sin resultados.</div>
          }
        </ui-dropdown-content>
      </ui-dropdown>
    </div>
  </div>
  
  <div class="assign-teachers__content">
    <div class="teachers-content__header">
      <ui-select label="Curso" variant="bordered" (onValueChange)="onSelectCourse($event.value)" [disabled]="selectedSchooBadges().length === 0"> 
        <ui-select-content>
          <ui-select-item value="" [disabled]="true">Seleccionar curso</ui-select-item>
          @for (item of courseList(); track $index) {
            <ui-select-item [value]="item.id!">{{item.NameCourseOfer | titlecase}}</ui-select-item>
          }
        </ui-select-content>
      </ui-select>

      <ui-select label="Materia" variant="bordered" (onValueChange)="onSelectSubject($event.value)" [disabled]="!selectedCourseOffer()">
        <ui-select-content>
          <ui-select-item value="" [disabled]="true">Seleccionar materia</ui-select-item>
          @for (item of subjectList(); track $index) {
            <ui-select-item [value]="item.id!">{{item.NameSubject | titlecase}}</ui-select-item>
          }
        </ui-select-content>
      </ui-select>

      <ui-select label="Grupo" variant="bordered" (onValueChange)="onSelectCourseGroup($event.value)" [disabled]="!selectedSubject()">
        <ui-select-content>
         <!--  <ui-select-item value="" [disabled]="true">Seleccionar grupo</ui-select-item> -->
          @for (item of groupList(); track $index) {
            <ui-select-item [value]="item.id!">{{item.name | titlecase}}</ui-select-item>
          }
        </ui-select-content>
      </ui-select>
    </div>
    <div class="group-list">
      @for (item of subjectGroupList(); track $index) {
        <div class="group-list__item">
          <div class="group-list__item-content">
            <div>{{item.id}}</div>
            <div><strong>{{item.subjects?.NameSubject | uppercase}}</strong></div>
            <div><strong>{{item.coursesgroup?.name | uppercase}}</strong></div>
          </div>
          <div class="group-list__item-assign" >
            <div class="assign-component" [ngStyle]="{'display': !displayAssing() ? 'none' : 'block' }">
              <assign-users [subjectGroup]="item"/>
            </div>
            <div class="assign-button" [ngClass]="{'assign-button--cancel': displayAssing()}" (click)="displayAssing.set(!displayAssing())">
              <em class="icon-i-plus"></em> {{displayAssing() ? 'Cancelar' : 'Asignar'}} 
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</section>