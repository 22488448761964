import { CommonModule } from '@angular/common';
import { Component, computed, inject, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import * as UiDropdwon from '@components/ui/ui-dropdown'
import * as UiSelect from '@components/ui/ui-select';
import { ICourseGroup } from '@models/course-group';
import { ICourseOffer } from '@models/course-offer';
import { ISubjectGroup } from '@models/subject-group';
import { ISubjects } from '@models/subjects';
import { ApiService } from '@services/api-service.service';
import Swal from 'sweetalert2';
import { IUserUserApp } from '@models/users';
import { AssignUsersComponent } from './components/assign-users/assign-users.component';
import { UiBadgeComponent } from '@components/ui/ui-badge/ui-badge.component';
import { UiInputComponent } from '@components/ui/ui-input/ui-input.component';
import { ISchool } from '@models/school';
import { UiButtonComponent } from '@components/ui/ui-button/ui-button.component';

interface SchoolItem { value: string; label: string }
@Component({
  selector: 'assign-teachers',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,

    UiButtonComponent,
    UiSelect.Root,
    UiSelect.Content,
    UiSelect.Item,

    UiDropdwon.Root,
    UiDropdwon.Trigger,
    UiDropdwon.Content,
    UiDropdwon.Item,

    UiBadgeComponent,
    UiInputComponent,
    AssignUsersComponent
  ],
  templateUrl: './assign-teachers.component.html',
  styleUrl: './assign-teachers.component.css',
})
export class AssignTeachersComponent {
  private _apiService = inject(ApiService);
  public openDropdown = signal(true);
  public allSchools = computed(() => this._apiService.schools());
  public schoolList = computed<(ISchool & {disabled?:boolean})[]>(() => {
    const searchValue = this._searchValue().toLowerCase();
    if (searchValue.length > 0) {
      return this.allSchools().filter(e => 
        e.NameTSchool && e.NameTSchool.toString().toLowerCase().includes(searchValue)
      );
    }
    return this.allSchools();
  });

  public selectedSchooBadges = signal<ISchool[]>([]);
  private _searchValue = signal<string>('');
  private _selectedSchoolIds = signal<number[]>([]);
  public selectedCourseOffer = signal<ICourseOffer | null>(null);
  public selectedSubject = signal<string | number | null>(null);
  public selectedGroup = signal<string | number | null>(null);
  public courseList = signal<ICourseOffer[]>([]);
  public subjectList = signal<ISubjects[]>([]);
  public groupList = signal<ICourseGroup[]>([]);
  public subjectGroupList = signal<ISubjectGroup[]>([]);
  public addedProfessors = signal<IUserUserApp[]>([]);
  public displayAssing = signal<boolean>(false);

  public onSelecteSchools(school: SchoolItem) {
    this.subjectGroupList.set([]);
    this.selectedSchooBadges.update(prev => {
      const currentSchool = this.allSchools().find(e => e.id === Number(school.value));
      if (!currentSchool) return prev;
      return [...prev, currentSchool]
    });

    this.toggleUser(true, Number(school.value));

    const schoolIds = this.selectedSchooBadges().map((e: ISchool) => Number(e.id));
    this._selectedSchoolIds.set(schoolIds);

    this.getCourses();
  }
  
  public removeSchoolBadge(id:number){
    this.selectedSchooBadges.set(this.selectedSchooBadges().filter(e => e.id !== id));
    this.toggleUser(false, id);
    this.getCourses();
  }

  private toggleUser(disable:boolean, id:number){
    this.schoolList().forEach(e => {
      if(e.id === id){
        e.disabled = disable;
        return;
      }
    })
  }

  public onSearchChange(event:Event){
    const value = (event.target as HTMLInputElement).value.toLowerCase()
    this._searchValue.set(value)
  }

  private getCourses(){
    //Reset variables
    this.selectedCourseOffer.set(null);
    this.selectedSubject.set(null);
    this.selectedGroup.set(null);

    this.courseList.set([]);
    this.subjectList.set([]);
    this.groupList.set([]);

    if(this.selectedSchooBadges().length === 0){
      this._selectedSchoolIds.set([])
      return;
    }

    const params = {
      path: 'CoursesOfers',
      filter: { where: { and: [{ SchoolID: { inq: this._selectedSchoolIds() } }, { IsActive: true }] } },
    };
    
    this._apiService.get<ICourseOffer>(params).subscribe({
      next: (resp: ICourseOffer[]) => {
        this.courseList.set(resp);
      },
      error: (err) => console.log(err),
      complete: () => Swal.close()
    });
  }

  public onSelectCourse(value:string){
    this.selectedSubject.set(null);
    this.selectedGroup.set(null);

    this.groupList.set([]);
    this.subjectGroupList.set([]);

    const courseOffer = this.courseList().find(e => e.id === Number(value));
    this.selectedCourseOffer.set(courseOffer!);

    if(this._selectedSchoolIds().length === 0) return;

    Swal.fire({
      title: "Buscando materias...",
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading(),
    });

    const params = {
      path: 'subjects',
      filter: { where: { CourseID: this.selectedCourseOffer()!.CourseID }},
    };

    this._apiService.get<ISubjects>(params).subscribe({
      next:(resp:ISubjects[])=>{
        this.subjectList.set(resp);
      },error:(err => {
        console.log(err);
      }),
      complete:() => Swal.close()
    })
  }

  public onSelectSubject(event:string){
    this.selectedSubject.set(Number(event));
    this.selectedGroup.set(null);

    this.subjectGroupList.set([]);
    this.groupList.set([]);

    if(!this.selectedCourseOffer()) return;

    Swal.fire({
      title: "Buscando grupos...",
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading(),
    });

    const params = {
      path: 'CourseGroups',
      filter: { where: { CourseOferID: this.selectedCourseOffer()!.id } },
    };

    this._apiService.get<ICourseGroup>(params).subscribe({
      next:(resp:ICourseGroup[]) => {
        this.groupList.set(resp);
      },error:(err => {
        console.log(err);
      }),
      complete:() => Swal.close()
    })
  }

  public onSelectCourseGroup(value:string){
    this.selectedGroup.set(Number(value));
    this.subjectGroupList.set([]);

    if(!this.selectedSubject()) return;

    Swal.fire({
      title: "Un momento por favor...",
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading(),
    });

    const params = {
      path: 'SubjectGroups',
      filter: {
        include: ['userapp', 'subjects', 'coursesgroup', { ProfessorToSubjectGroups: 'professor' }],
        where: {
          and: [ { CourseGroupID: this.selectedGroup() }, { SubjectID: this.selectedSubject() } ],
        },
      },
    };

    this._apiService.get<ISubjectGroup>(params).subscribe({
      next:((resp:ISubjectGroup[]) =>{
        this.subjectGroupList.set(resp);
      }),error:(err => {
        console.log(err);
      }),
      complete:() => Swal.close()
    })
  }
}
