import {  Component, computed,  ElementRef,  inject,  } from '@angular/core';
import { UiSelectService } from '../ui-select.service';
import { CommonModule, DOCUMENT } from '@angular/common';

@Component({
  selector: 'ui-select-content',
  templateUrl: './ui-select-content.component.html',
  styleUrl: './ui-select-content.component.css',
  imports: [CommonModule],
  standalone: true
})
export class UiSelectContentComponent {
  private _ref = inject(ElementRef);
  private DOCUMENT = inject(DOCUMENT);

  private _selectService = inject(UiSelectService);
  public open = computed(() => this._selectService.open());

  public isRightPosition = computed(() => {
    if(!this.open())
      return false;

    const windowWidth = this.DOCUMENT.body.getBoundingClientRect().width;
    const contentHolder = this._ref.nativeElement.querySelector('.content-holder');
    const boxRight = contentHolder.getBoundingClientRect().right;
    
   return (windowWidth < boxRight) && this.open();
  });

  public isBottonPosition = computed(() => {
    if(!this.open())
      return false;

    const windowHeight = this.DOCUMENT.documentElement.clientHeight;
    const contentHolder = this._ref.nativeElement.querySelector('.content-holder');
    
    const boxBottom = contentHolder.getBoundingClientRect().bottom;
    return (windowHeight < boxBottom) && this.open();
  });
}