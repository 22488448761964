import {
  Component,
  inject,
  input
} from '@angular/core';
import { UiSelectService } from '../ui-select.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ui-select-item',
  imports:[CommonModule],
  templateUrl: './ui-select-item.component.html',
  styleUrl: './ui-select-item.component.css',
  standalone: true,
})
export class UiSelectItemComponent  {
  private _selectService = inject(UiSelectService);
  value = input.required<string | number | boolean>();
  disabled = input<boolean>(false);

  public setItem() {
    if(this.disabled()) return;
    this._selectService.emitSelected(this.value());
  }
}
