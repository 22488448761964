<div class="events-modal">
  <div class="events-modal__container">
    <div class="events-modal__body">
      <!-- HEADER -->
      <div class="events-modal__header">
        <h2 class="header-title">Crear evento</h2>
        <ui-button color="primary" (onClick)="onModalClose.emit()">
          <em class="icon-i-close close-icon"></em>
        </ui-button>
      </div>

      <!-- CONTENT -->
      <div class="events-modal__content">
        <div class="dropdown-input">
          <ui-dropdown (onValueChange)="selectedSchool.set($event)" [useToggle]="false">
            <ui-dropdown-trigger>
              <ui-input [invalid]="this.eventsForm().get('schoolID')
                !.hasError('required') && isSending()" [value]="selectedSchool()?.label || ''"
                (keyup)="getSchools($event)" variant="bordered" placeholder="Buscar Liceo">
                <em class="icon-i-search search-icon" start-decorator></em>
              </ui-input>
            </ui-dropdown-trigger>

            <ui-dropdown-content>
              <div *ngIf="filteredSchools().length === 0">Sin resultados</div>
              <ui-dropdown-item [value]="school.id!" *ngFor="let school of filteredSchools()">
                {{ school.NameTSchool }}
              </ui-dropdown-item>
            </ui-dropdown-content>
          </ui-dropdown>
          <div class="validation-feedbacK">
            @if (this.eventsForm().get('schoolID')!.hasError('required') && isSending()) {
            <small class="validation-feedback__text">Este campo es obligatorio</small>
            }
          </div>
        </div>

        <form [formGroup]="eventsForm()" class="form" (ngSubmit)="saveData()">
          <div class="input-holder">
            <ui-input [invalid]="this.eventsForm().get('title')
              !.hasError('required') && isSending()" label="Título" variant="underline" formControlName="title">
            </ui-input>

            <div class="validation-feedbacK">
              @if (this.eventsForm().get('title')!.hasError('required') && isSending()) {
              <small class="validation-feedback__text">Este campo es obligatorio</small>
              }
            </div>
          </div>

          <div class="date-actions">
            <div class="input-holder">
              <ui-date-input [invalid]="this.eventsForm().get('dateStart')
                !.hasError('required') && isSending()" formControlName="dateStart" type="datetime-local"
                variant="underline" label="Fecha de inicio" />

              <div class="validation-feedbacK">
                @if (this.eventsForm().get('dateStart')!.hasError('required') && isSending()) {
                <small class="validation-feedback__text">Este campo es obligatorio</small>
                }
              </div>
            </div>

            <div class="input-holder">
              <ui-date-input [invalid]="this.eventsForm().get('dateEnd')
                !.hasError('required') && isSending() " formControlName="dateEnd" type="datetime-local"
                variant="underline" label="Fecha final" />

              <div class="validation-feedbacK">
                @if (this.eventsForm().get('dateEnd')!.hasError('required') && isSending()) {
                <small class="validation-feedback__text">Este campo es obligatorio</small>
                }
              </div>
            </div>
          </div>

          <div>
            <h2 class="section-title">Frecuencia</h2>
            <div class="frequency-actions">
              <h2 class="section-subtitle">Repetir cada</h2>
              <div>
                <ui-select [invalid]="this.eventsForm().get('frequency')!.hasError('required') && isSending()"
                  formControlName="frequency" label="Tipo frecuencia">
                  <ui-select-content>
                    @for (item of [].constructor(10); track $index) {
                    <ui-select-item [value]="$index + 1">{{ $index + 1}}</ui-select-item>
                    }
                  </ui-select-content>
                </ui-select>

                <div class="validation-feedbacK">
                  @if (this.eventsForm().get('frequency')!.hasError('required') && isSending()) {
                  <small class="validation-feedback__text">Este campo es obligatorio</small>
                  }
                </div>
              </div>
              <label class="frequency-actions__label">
                <em class="icon-i-angle-right arrow-up"></em>
                <em class="icon-i-angle-right arrow-down"></em>
              </label>
              <div>
                <ui-select [invalid]="this.eventsForm().get('frequencyType')!.hasError('required') && isSending()"
                  formControlName="frequencyType" label="Tipo frecuencia">
                  <ui-select-content>
                    <ui-select-item value="day">Día</ui-select-item>
                    <ui-select-item value="week">Semana</ui-select-item>
                    <ui-select-item value="month">Mes</ui-select-item>
                  </ui-select-content>
                </ui-select>

                <div class="validation-feedbacK">
                  @if(this.eventsForm().get('frequencyType')!.hasError('required') && isSending()) {
                  <small class="validation-feedback__text">Este campo es obligatorio</small>
                  }
                </div>
              </div>
            </div>
          </div>

          <div>
            <h2 class="section-title">Se repite</h2>
            <div>
              <days-selector [invalid]="this.eventsForm().get('frequencyDays')!.hasError('required') && isSending()"
                formControlName="frequencyDays" />
              <div class="validation-feedbacK">
                @if (this.eventsForm().get('frequencyDays')!.hasError('required') && isSending()) {
                <small class="validation-feedback__text">Este campo es obligatorio</small>
                }
              </div>
            </div>
          </div>

          <div class="guest-actions">
            <em class="icon-i-create-academic-groups icon guest-icon"></em>
            <div class="dropdown-input">
              <ui-dropdown (onValueChange)="selectGuest($event.value)" [closeOnSelect]="false" [useToggle]="false">
                <ui-dropdown-trigger>
                  <ui-input placeholder="Buscar invitados" (keyup)="findGuest($event)">
                    <em class="icon-i-search search-icon" start-decorator></em>
                  </ui-input>
                </ui-dropdown-trigger>

                <ui-dropdown-content>
                  <div *ngIf="guests().length === 0">Sin resultados</div>
                  <ui-dropdown-item [value]="guest.id" *ngFor="let guest of guests()">
                    {{ guest.name | titlecase }}
                  </ui-dropdown-item>
                </ui-dropdown-content>
              </ui-dropdown>
            </div>
          </div>

          <ul class="guest-list">
            @for (item of selectedGuests(); track $index) {
            <li class="guest-item">
              <div class="guest-item__content">
                <h2 class="guest-item__content-name">
                  {{ item.name | titlecase }}
                </h2>
                <p class="guest-item__content-email">{{ item.email }}</p>
              </div>
              <button type="button" class="guest-item__delete" (click)="removeGuest(item.id, item.eventUserId)">
                <em class="icon-i-trash trash-icon"></em>
              </button>
            </li>
            }@empty { @if (loadingGuest()) {
            <div class="guest-item__skeleton">
              <div class="guest-item__skeleton-circle"></div>
              <div class="guest-item__skeleton-content">
                <div class="guest-item__skeleton-bar"></div>
                <div class="guest-item__skeleton-bar guest-item__skeleton-bar--sm"></div>
              </div>
            </div>
            }}
          </ul>

          <ui-button variant="solid" color="primary">Guardar evento</ui-button>
        </form>
      </div>
    </div>

    <!--  @if (eventsForm()) {
      <pre>{{ eventsForm().value | json }}</pre>
    } -->
  </div>
</div>