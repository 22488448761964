<section class="events-container">
  <div class="events-container__header">
    <!-- FILTER BY SCHOOL -->
    <div class="dropdown-input">
      <ui-dropdown (onValueChange)="getEvents($event)" [useToggle]="false">
        <ui-dropdown-trigger>
          <div>
            <ui-input [value]="selectedSchool()?.label || ''" (keyup)="getSchools($event)" variant="bordered"
              placeholder="Buscar Liceo">
              <em class="icon-i-search search-icon" start-decorator></em>
            </ui-input>
          </div>
        </ui-dropdown-trigger>

        <ui-dropdown-content>
          <div *ngIf="filteredSchools().length === 0">Sin resultados</div>
          <ui-dropdown-item [value]="school.id!" *ngFor="let school of filteredSchools()">
            {{ school.NameTSchool }}
          </ui-dropdown-item>
        </ui-dropdown-content>
      </ui-dropdown>
    </div>

    <div class="header-button">
      <ui-button color="primary" variant="solid" (onClick)="toggleModal()">
        Crear evento
        <em class="icon-i-manage-schedules events-icon" end-decorator></em>
      </ui-button>
    </div>
  </div>

  <div class="events-list">
    @for (item of events(); track $index) {
    <div class="events-list__item">
      <div class="events-list__item-info">
        <span class="events-list__item-date">{{item.dateStart | date:'dd MMM' }}</span>
        <div class="events-list__item-content">
          <span class="events-content__text events-content__text--title">{{item.title}}</span>
          <span class="events-content__text">{{item.dateEnd | date}}</span>
        </div>
      </div>

      <div class="events-list__item-actions">
        <button class="events-actions__button" (click)="setEditableInfo(item)"><em
          class="icon-i-pen edit-icon"></em>Editar</button>
        <button class="events-actions__button" (click)="removeEventsAction(item)"><em
          class="icon-i-trash delete-icon"></em>Eliminar</button>
      </div>
    </div>
    }

  </div>

  @if (openModal()) {
  <events-modal [editableInfo]="eventToEdit()" (onModalClose)="toggleModal(); this.eventToEdit.set(null)"
    (refreshData)="refreshData()" />
  }
</section>