import {
  Component,
  OnDestroy,
  OnInit,
  Renderer2,
  computed,
  inject,
  signal,
} from '@angular/core';
import { ISchool } from '@models/school';
import { EventsModalComponent } from './components/events-modal/events-modal.component';

import * as UiDropdown from '@components/ui/ui-dropdown';

import { UiButtonComponent } from '@components/ui/ui-button/ui-button.component';
import { ApiService } from '@services/api-service.service';
import { UiInputComponent } from '@components/ui/ui-input/ui-input.component';
import { IEvent } from '@models/events';
import { Subject, takeUntil } from 'rxjs';
import { CommonModule, DOCUMENT } from '@angular/common';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-events',
  standalone: true,
  imports: [
    CommonModule,
    EventsModalComponent,
    UiDropdown.Root,
    UiDropdown.Content,
    UiDropdown.Trigger,
    UiDropdown.Item,
    UiButtonComponent,
    UiInputComponent,
  ],
  templateUrl: './events.component.html',
  styleUrl: './events.component.css',
})
export class EventsComponent implements OnInit, OnDestroy {
  private _apiService = inject(ApiService);
  private _document = inject(DOCUMENT);
  private _renderer = inject(Renderer2);

  public mainSchools = computed<ISchool[]>(() => this._apiService.schools());
  public filteredSchools = signal<ISchool[]>([]);
  public selectedSchool = signal<{ label: string; value: any } | null>(null);
  public openModal = signal<boolean>(false);
  public events = signal<IEvent[]>([]);
  public eventToEdit = signal<IEvent | null>(null);
  private _destroy$: Subject<void> = new Subject();

  ngOnInit(): void {}

  /**
   * Filter events by schoolId
   * @param event
   */
  public getEvents(event: { label: string; value: string }) {
    Swal.fire({
      text: `Buscando eventos en la escuela ${event.label}`,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading(),
    });

    this.selectedSchool.set(event);

    const params = {
      path: 'ScheduleEvents',
      filter: { where: { schoolID: this.selectedSchool()!.value } },
    };

    this._apiService
      .get<IEvent>(params)
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: (events: IEvent[]) => {
          Swal.close();
          this.events.set(events);
        },
        error: (err) => {
          Swal.fire({
            icon: 'error',
            text: `Error al cargar las escuelas: ${err.message}`,
            allowOutsideClick: false,
          });
          console.log(err);
        },
      });
  }

  /**
   * Refresh events data
   */
  public refreshData() {
    if (this.selectedSchool()) this.getEvents(this.selectedSchool()!);
  }

  /**
   * Remove eventos from db
   * @param event
   * @returns
   */
  public removeEventsAction(event: IEvent) {
    Swal.fire({
      icon: 'warning',
      text: `Seguro que deseas eliminar el evento ${event.title}?`,
      showCancelButton: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) this.removeEvents(event);
    });
  }

  /**
   * Remove events qry
   * @param event
   */
  public removeEvents(event: IEvent) {
    Swal.fire({
      text: `Eliminando ${event.title}`,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading(),
    });

    this._apiService
      .delete<IEvent>({ path: `ScheduleEvents/${event.id}` })
      .pipe(takeUntil(this._destroy$))
      .subscribe({
        next: () => {
          const eventsCopy = [...this.events()].filter(
            (e) => e.id !== event.id
          );

          this.events.set(eventsCopy);

          Swal.fire({
            icon: 'success',
            text: `El evento se eliminó exitosamente.`,
            showCancelButton: true,
            allowOutsideClick: false,
          });
        },
        error: (err) => {
          Swal.fire({
            icon: 'warning',
            text: `Error al eliminar el evento: ${err.message}`,
            showCancelButton: true,
            allowOutsideClick: false,
          });
          console.log(err);
        },
      });
  }

  //Toggle modal
  public toggleModal() {
    this.openModal.set(!this.openModal());
    this._renderer.setStyle(
      this._document.body,
      'overflow',
      this.openModal() === true ? 'hidden' : 'auto'
    );
  }

  /**
   * Store info to edit
   * @param event
   */
  public setEditableInfo(event: IEvent) {
    this.eventToEdit.set(event);
    this.toggleModal();
  }

  /**
   * Filter schools
   * @param event
   * @returns
   */
  public getSchools(event: Event) {
    const value = (event.target as HTMLInputElement).value;

    if (value.length <= 3) {
      this.filteredSchools.set([]);
      return;
    }

    const filteredData = [...this.mainSchools()]
      .filter((e) => e.NameTSchool?.toLocaleLowerCase().trim().includes(value))
      .splice(0, 4);

    this.filteredSchools.set(filteredData);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
