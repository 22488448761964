<div class="content-card-schedule">
  <h5 class="mb-0 text-black uppercase" style="padding-bottom: 15px;">
    <strong >
        Horario Docentes
    </strong>
    <small class="text-muted" style="font-size: 1rem; text-transform: uppercase;">
    </small>
  </h5>

    <div class="input-container">
              <div class="dropdown-input">
                <label class="inputs-labels">Filtrar Docente</label>
                <ui-dropdown
                  (onValueChange)="selectGuest($event.value)"
                  [closeOnSelect]="false"
                  [useToggle]="false"
                >
                  <ui-dropdown-trigger>
                    <ui-input 
                    placeholder="Buscar por correo o documento"
                    value-property-name="email" display-property-name="email"
                    name="email" min-chars="3" no-match-found-text="No Se Encontró Ningun Resultado" 
                    autocomplete="off" required id="InfoSelected"
                    (keyup)="findGuest($event)"
                    >
                    </ui-input>
                  </ui-dropdown-trigger>

                  @if(guests().length > 0){
                  <ui-dropdown-content>
                    @for (guest of guests(); track $index) {
                    <ui-dropdown-item [value]="guest.id">
                      {{ guest.name | titlecase }}
                    </ui-dropdown-item>
                    }
                  </ui-dropdown-content>
                  }
                </ui-dropdown>
              </div>
              <!--Filtro Escuelas-->
              <div class="dropdown-input">
                <label class="inputs-labels">Filtro por escuela</label>
                <select #schoolSelector class="inputs" (change)="onSchoolChange($event)">
                  <option class="placeholder-option" value="" selected> Seleccionar escuela </option>
                  @for (school of schoolList; track school.id) {
                    <option [value]="school.id">{{school.NameTSchool}}</option>
                  }
                </select>
              </div>
              <div class="dropdown-input">
                <label class="inputs-labels">Filtro por oferta</label>
                <select #offerSelector class="inputs" [disabled]="!selectedSchoolsList" (change)="onCourseChange($event)">
                  <option class="placeholder-option" value=""  selected> Seleccionar oferta </option>
                  @for (offer of offerList; track offer.id) {
                    <option [value]="offer.id">{{offer.NameCourseOfer}}</option>
                  }
                </select>
              </div>
    </div>
    <!--Listas de filtros-->
    @if(selectedGuests().length >= 1){<label class="inputs-labels">Filtro Docentes</label>}
    <ul class="guest-list">
      @for (item of selectedGuests(); track $index) {
      <li class="guest-item">
        <button
          type="button"
          class="guest-item__delete"
          (click)="removeGuest(item.id)"
        >
          <em class="icon-i-close"></em>
        </button>
        <div class="guest-item__content">
          <h2 class="guest-item__content-name">
            {{ item.name | titlecase }}
          </h2>
        </div>
      </li>
      }@empty { @if (loadingGuest()) {
        <div class="guest-item__skeleton">
          <div class="guest-item__skeleton-circle"></div>
          <div class="guest-item__skeleton-content">
            <div class="guest-item__skeleton-bar"></div>
            <div
              class="guest-item__skeleton-bar guest-item__skeleton-bar--sm"
            ></div>
          </div>
        </div>
      }}
    </ul>

  @if(selectedSchoolsList){<label class="inputs-labels">Filtro Escuelas</label>
    <ul class="guest-list">
        <li class="guest-item">
          <button
            type="button"
            class="guest-item__delete"
            (click)=" resetSchoolSelection()"
          >
            <em class="icon-i-close"></em>
          </button>
          <div class="guest-item__content">
            <h2 class="guest-item__content-name">
              {{ selectedSchoolsList }}
            </h2>
          </div>
        </li>

    </ul>
  }
    <!--Filtro Offers-->
  @if(selectedOfferList){<label class="inputs-labels">Filtro Ofertas</label>
  <ul class="guest-list">
    <li class="guest-item">
      <button
        type="button"
        class="guest-item__delete"
        (click)="resetOfferSelection()"
      >
        <em class="icon-i-close"></em>
      </button>
      <div class="guest-item__content">
        <h2 class="guest-item__content-name">
          {{ selectedOfferList }}
        </h2>
      </div>
    </li>
  </ul>
  }
    <div class="div-button-list">
      <button type="button" class="button-list" (click)="listView = !listView">{{listView ? "Ver en lista":"Ver Calendario"}}</button>
    </div>

    <div [hidden]="!listView">

      <div class="row text-center m-3">
        <div class="col-md-4">
          <div class="btn-group">
            <button class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
              (viewDateChange)="closeOpenMonthViewDay()">
              <i class="fa fa-angle-left"></i>
            </button>
            <button class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
              Hoy
            </button>
            <button class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
              (viewDateChange)="closeOpenMonthViewDay()">
              <i class="fa fa-angle-right"></i>
            </button>
          </div>
        </div>
        <div class="col-md-4">
          <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
        </div>
      </div>

      <mwl-calendar-week-view
      [viewDate]="viewDate"
      [events]="events"
      [refresh]="refresh"
      [snapDraggedEvents]="false" [hourSegments]="4" 
      [weekStartsOn]="weekStartsOn" [weekendDays]="weekendDays" 
      [locale]="locale"
      [dayStartHour]="6" [dayEndHour]="23"
      >
    </mwl-calendar-week-view>
      
    </div>
    <div [hidden]="listView">
      @if (scheduleList.length !== 0) {
        @for (event of scheduleList; track $index) {
          <div class="list-view">
            <p class="text-course-name">{{event.NameSubjectGroup}}</p>
            <p>{{event.coursesgroup.name}}</p>
            <p>{{(event.scheduleSubjects.HourEnd | date:'MM/dd/yyyy')}}</p>
          </div>
        }
      }@else {
        <div class="offer-message">
          No se han encontrado ofertas que mostrar
        </div>
      }
    </div>
</div>