// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[5].rules[0].oneOf[0].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[5].rules[0].oneOf[0].use[2]!../node_modules/angular-calendar/css/angular-calendar.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* angular-cli file: src/styles.css */

:root {
  --ms-primary: 206 100% 50%;
  --ms-default: 220 72% 29%;
  --ms-red: 0 100% 60%;
  --ms-gray: 0 0% 51%;
  --ms-green: 104 100% 38%;
  --ms-orange: 35 96% 59%;

  /* Main input styles */
  --ms-input-container-height: 56px;
  --ms-input-filled-padding: 24px 16px 8px 16px;
  --ms-input-empty-padding: 17px 17px;
  --ms-input-label-position: -116%;

  /* Main select styles */
  --ms-select-filled-padding: 24px 30px 8px 16px;
  --ms-select-empty-padding: 17px 30px 17px 17px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.autohide {
  display: none;
}

/* INVALID FIELDS */
.validation-feedback {
  margin: 0;
}

.validation-feedback:empty {
  display: none;
}

.validation-feedback__text {
  color: hsl(var(--ms-red) / 0.7) !important;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/styles.css"],"names":[],"mappings":"AAAA,qCAAqC;;AAGrC;EACE,0BAA0B;EAC1B,yBAAyB;EACzB,oBAAoB;EACpB,mBAAmB;EACnB,wBAAwB;EACxB,uBAAuB;;EAEvB,sBAAsB;EACtB,iCAAiC;EACjC,6CAA6C;EAC7C,mCAAmC;EACnC,gCAAgC;;EAEhC,uBAAuB;EACvB,8CAA8C;EAC9C,8CAA8C;AAChD;;AAEA;EACE,sBAAsB;EACtB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,aAAa;AACf;;AAEA,mBAAmB;AACnB;EACE,SAAS;AACX;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,0CAA0C;EAC1C,iBAAiB;AACnB","sourcesContent":["/* angular-cli file: src/styles.css */\n@import \"../node_modules/angular-calendar/css/angular-calendar.css\";\n\n:root {\n  --ms-primary: 206 100% 50%;\n  --ms-default: 220 72% 29%;\n  --ms-red: 0 100% 60%;\n  --ms-gray: 0 0% 51%;\n  --ms-green: 104 100% 38%;\n  --ms-orange: 35 96% 59%;\n\n  /* Main input styles */\n  --ms-input-container-height: 56px;\n  --ms-input-filled-padding: 24px 16px 8px 16px;\n  --ms-input-empty-padding: 17px 17px;\n  --ms-input-label-position: -116%;\n\n  /* Main select styles */\n  --ms-select-filled-padding: 24px 30px 8px 16px;\n  --ms-select-empty-padding: 17px 30px 17px 17px;\n}\n\n* {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n}\n\n.autohide {\n  display: none;\n}\n\n/* INVALID FIELDS */\n.validation-feedback {\n  margin: 0;\n}\n\n.validation-feedback:empty {\n  display: none;\n}\n\n.validation-feedback__text {\n  color: hsl(var(--ms-red) / 0.7) !important;\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
