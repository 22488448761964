import { Routes } from '@angular/router';
import { msDataResolver } from './resolvers/ms-data-resolver.resolver';
import { EventsComponent } from './pages/private/events/events.component';
import { AssignTeachersComponent } from './pages/private/assign-teachers/assign-teachers.component';
import { ScheduleTableComponent } from './pages/private/events/components/schedule-table/schedule-table.component';

export const routes: Routes = [
  {
    path: 'manage-schedule',
    resolve:{
      msData: msDataResolver
    },
    children: [
      {
        path: 'events',
        component: EventsComponent,
      },
      {
        path: 'assign-teachers',
        component: AssignTeachersComponent,
      },
      {
        path: 'academic-charge',
        component: ScheduleTableComponent,
      },
    ],
  },
  { path: '**', redirectTo: '/user/my-account' },
];
