<div class="select-content">
  <div class="content-holder" 
    [ngClass]="{
      'content-holder--right' : isRightPosition(),
      'content-holder--left' : !isRightPosition(),
      'content-holder--bottom': isBottonPosition(),
      'content-holder--top': !isBottonPosition(),
      'content-holder--open':open()}">
    <ul class="content-list">
      <ng-content />
    </ul>
  </div>
</div>