import {
  Component,
  ElementRef,
  HostListener,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  inject,
  input,
  output,
} from '@angular/core';
import { UiDropdownService } from './ui-dropdown.service';
import { Subject, takeUntil } from 'rxjs';
import { IDropdownItem } from '../models/dropdown-item';

@Component({
  selector: 'ui-dropdown',
  templateUrl: './ui-dropdown.component.html',
  styleUrl: './ui-dropdown.component.css',
  standalone: true,
  imports: [],
  providers: [UiDropdownService],
})
export class UiDropdownComponent implements OnInit, OnChanges, OnDestroy {
  private _dropdownService = inject(UiDropdownService);
  private _ref = inject(ElementRef);

  closeOnSelect = input<boolean>(true);
  useToggle = input<boolean>(true);
  onValueChange = output<IDropdownItem>();
  private _destroy$: Subject<void> = new Subject();

  ngOnInit(): void {
    this._dropdownService.$onItemChange
      .pipe(takeUntil(this._destroy$))
      .subscribe((item) => {
        this.onValueChange.emit(item);
        if (this.closeOnSelect()) this._dropdownService.trigger.set(false);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['useToggle'] && !changes['useToggle'].firstChange)
      this._dropdownService.useToggle.set(this.useToggle());
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  @HostListener('document:mousedown', ['$event'])
  onClick(event: MouseEvent) {
    const target = event.target as HTMLElement;

    if (!this._ref.nativeElement.contains(target))
      this._dropdownService.trigger.set(false);
  }
}