import { Injectable, signal } from '@angular/core';
import { Subject } from 'rxjs';
import { IDropdownItem } from '../models/dropdown-item';

@Injectable({
  providedIn: 'root'
})
export class UiSelectService {
  public itemList = signal<IDropdownItem[]>([]);
  public $onItemChange = new Subject<IDropdownItem>();

  //Curren clicked
  public open = signal<boolean>(false);

  /**
   * `emitSelected` triggers the selection of an item based on the provided value.
   * It finds the corresponding item in the `itemList` and emits it through the `$onItemChange` subject.
   *
   * @param value - The value of the item to be selected.
   */
  public emitSelected(value: string | number | boolean) {
    const itemToAdd = this.itemList().find(e => e.value == value.toString());
    if (itemToAdd) this.$onItemChange.next(itemToAdd);
  }

  /**
   * `setVisualItem` finds and returns the item from the `itemList` that matches the provided value.
   * This method is used to update the visual representation of the selected item in the dropdown.
   *
   * @param value - The value of the item to be visually selected.
   * @returns The matching `IDropdownItem` or `null` if no match is found.
   */
  public setVisualItem(value: string | number | boolean) : IDropdownItem | null {
    const itemToAdd = this.itemList().find(e => e.value == value.toString());
    return itemToAdd ? itemToAdd : null;
  }
}