import { CommonModule } from '@angular/common';
import { Component, computed, input, output } from '@angular/core';

interface IVariant {
  default: string;
  primary: string;
  destructive: string;
}

interface IVariants {
  solid: IVariant;
  bordered: IVariant;
  ghost: IVariant;
}

@Component({
  selector: 'ui-button',
  templateUrl: './ui-button.component.html',
  styleUrl: './ui-button.component.css',
  standalone: true,
  imports: [CommonModule]
})
export class UiButtonComponent {
  disabled = input<boolean>(false);
  color = input<'default' | 'primary' | 'destructive'>('default');
  variant = input<'solid' | 'bordered' | 'ghost'>('solid');
  type = input<'button' | 'submit'>('submit');
  onClick = output<Event>();

  public styles = computed(() => {
    const variants: IVariants = {
      solid: {
        default: 'solid--default',
        primary: 'solid--primary',
        destructive: 'solid--destructive'
      },
      bordered: {
        default: 'bordered--default',
        primary: 'bordered--primary',
        destructive: 'bordered--destructive',
      },
      ghost: {
        default: 'ghost--default',
        primary: 'ghost--primary',
        destructive: 'ghost--destructive',
      },
    };
    
    return `${variants[this.variant()][this.color()]} ${this.variant()} ${this.disabled() ? 'disabled disabled--' + this.variant() : ''}`;
  });
}