import { CommonModule } from '@angular/common';
import {
  Component,
  OnDestroy,
  OnInit,
  computed,
  inject,
  input,
  output,
  signal,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { UiButtonComponent } from '@components/ui/ui-button/ui-button.component';
import { UiDateInputComponent } from '@components/ui/ui-date-input/ui-date-input.component';
import { UiDropdownContentComponent } from '@components/ui/ui-dropdown/ui-dropdown-content/ui-dropdown-content.component';
import { UiDropdownItemComponent } from '@components/ui/ui-dropdown/ui-dropdown-item/ui-dropdown-item.component';
import { UiDropdownTriggerComponent } from '@components/ui/ui-dropdown/ui-dropdown-trigger/ui-dropdown-trigger.component';
import { UiDropdownComponent } from '@components/ui/ui-dropdown/ui-dropdown.component';
import { UiInputComponent } from '@components/ui/ui-input/ui-input.component';
import { UiSelectContentComponent } from '@components/ui/ui-select/ui-select-content/ui-select-content.component';
import { UiSelectItemComponent } from '@components/ui/ui-select/ui-select-item/ui-select-item.component';
import { UiSelectComponent } from '@components/ui/ui-select/ui-select.component';
import { ISchool } from '@models/school';
import { ApiService } from '@services/api-service.service';
import {
  Observable,
  Subject,
  debounceTime,
  filter,
  forkJoin,
  of,
  switchMap,
  takeUntil,
} from 'rxjs';
import { IEvent } from '@models/events';
import { IEventUser } from '@models/event-user';
import Swal from 'sweetalert2';
import { DaysSelectorComponent } from '../days-selector/days-selector.component';
import { IUserUserApp } from '@models/users';


@Component({
  selector: 'events-modal',
  standalone: true,
  imports: [
    CommonModule,
    UiDropdownComponent,
    UiDropdownTriggerComponent,
    UiDropdownContentComponent,
    UiDropdownItemComponent,
    UiButtonComponent,
    UiDateInputComponent,
    UiInputComponent,
    UiSelectComponent,
    UiSelectContentComponent,
    UiSelectItemComponent,
    ReactiveFormsModule,
    DaysSelectorComponent,
  ],
  templateUrl: './events-modal.component.html',
  styleUrl: './events-modal.component.css',
})
export class EventsModalComponent implements OnInit, OnDestroy {
  private _apiService = inject(ApiService);

  editableInfo = input<IEvent | null>();
  onModalClose = output<void>();
  refreshData = output<void>();

  private _fb: FormBuilder = new FormBuilder();

  public mainSchools = computed<ISchool[]>(() => this._apiService.schools());
  public isSending = signal<boolean>(false);
  public filteredSchools = signal<ISchool[]>([]);
  public selectedSchool = signal<{ label: string; value: string } | null>(null);
  public loadingGuest = signal<boolean>(false);
  private _selectedGuests = signal<(IUserUserApp & { eventUserId?: number })[]>([]);
  private _filteredGuests = signal<IUserUserApp[]>([]);
  private _guestSubject$: Subject<string> = new Subject();
  private _destroy$: Subject<void> = new Subject();
  public eventsForm = signal<FormGroup>(
    this._fb.group({
      schoolID: [{ value: '', disabled: false }, [Validators.required]],
      title: [{ value: '', disabled: false }, [Validators.required]],
      dateStart: [{ value: '', disabled: false }, [Validators.required]],
      dateEnd: [{ value: '', disabled: false }, [Validators.required]],
      frequency: [{ value: '', disabled: false }, [Validators.required]],
      frequencyType: [{ value: '', disabled: false }, [Validators.required]],
      frequencyDays: [{ value: '', disabled: false }, [Validators.required]],
      createdBy: [{ value: '', disabled: false }, [Validators.required]],
      lastModifiedBy: [{ value: '', disabled: false }, [Validators.required]],
      createdAt: [{ value: '' }, [Validators.required]],
      id: [''],
    })
  );

  //Displayed in search dropdown
  public guests = computed<{ name: string; id: number }[]>(() => {
    return this._filteredGuests().map((e) => ({
      name: this.clearName(e),
      id: e.id!,
    }));
  });

  //Displayed when we select a guest
  public selectedGuests = computed<
    {
      name: string;
      id: number;
      email: string;
      eventUserId: number | undefined;
    }[]
  >(() => {
    return this._selectedGuests().map((e) => ({
      eventUserId: e.eventUserId,
      name: this.clearName(e),
      email: e.email,
      id: e.id!,
    }));
  });

  ngOnInit(): void {
    //Listen for guest search input
    this._guestSubject$
      .pipe(
        takeUntil(this._destroy$),
        filter((value) => value.length > 3),
        debounceTime(300),
        switchMap((value) => this.guestQry(value)),
      ).subscribe((guests: IUserUserApp[]) => {
        this._filteredGuests.set(guests);
      });

    if (!this.editableInfo()) return;

    this.patchData();
  }

  /**
   * Update form with this.editableInfo()
   */
  private patchData() {
    this.eventsForm.update((event) => {
      event.patchValue({
        ...this.editableInfo(),
        frequency: this.editableInfo()?.frequency.toString(),
        dateStart: this.editableInfo()?.dateStart,
        dateEnd: this.editableInfo()?.dateEnd,
        lastModifiedBy: this._apiService.userInfo()!.id,
      });

      return event;
    });

    //Get school to load automatically
    const currentSchool = this.mainSchools().find(
      (e) => e.id === this.editableInfo()?.schoolID
    );
    const schoolItem = {
      value: (currentSchool?.id || 0).toString(),
      label: currentSchool?.NameTSchool || '',
    };
    this.selectedSchool.set(schoolItem);

    //Get guests
    const params = {
      path: 'ScheduleEventUsers',
      filter: {
        where: { scheduleEventID: this.editableInfo()!.id },
        include: ['scheduleEventUser'],
      }
    };

    this.loadingGuest.set(true);
    this._apiService.get<IEventUser>(params).subscribe({
      next: (guests: IEventUser[]) => {
        const mappedGuest = guests.map((e) => ({
          ...e.scheduleEventUser!,
          eventUserId: e.id,
        }));
        this._selectedGuests.set(mappedGuest);
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => this.loadingGuest.set(false),
    });
  }

  /**
   * Remove selected guests
   * @param id
   */
  public removeGuest(userId: number, eventUserId: number | undefined) {
    if (this.editableInfo() && eventUserId) {
      Swal.fire({
        text: 'Eliminado invitados...',
        showConfirmButton: true,
        allowOutsideClick: false,
        didOpen: () => Swal.showLoading(),
      });

      this._apiService
        .delete<IEventUser>({ path: `ScheduleEventUsers/${eventUserId}` })
        .pipe(takeUntil(this._destroy$))
        .subscribe({
          next: () => {},
          error: (err) => {
            Swal.fire({
              icon: 'error',
              text: `Error al eliminar el invitado: ${err.message}`,
              showConfirmButton: true,
              allowOutsideClick: false,
            });
            console.log(err);
          },
          complete: () => Swal.close(),
        });
    }

    const getUser = this._selectedGuests().filter((e) => e.id !== userId);
    this._selectedGuests.set(getUser);
  }

  /**
   * Save or update
   * @returns
   */
  public saveData() {
    this.isSending.set(true);

    const params = {
      path: 'ScheduleEvents',
      data: this.eventsForm().value,
    };

    if (!this.editableInfo()) {
      this.eventsForm.update((form) => {
        form.patchValue({
          schoolID: this.selectedSchool()?.value || null,
          createdBy: this._apiService.userInfo()!.id,
          lastModifiedBy: this._apiService.userInfo()!.id,
          createdAt: new Date(),
        });
        return form;
      });

      const { id, ...dataWithoutId } = this.eventsForm().value;
      params.data = dataWithoutId;
    }

    if (!this.eventsForm().valid) {
      Swal.close();
      return;
    }

    Swal.fire({
      text: `Por favor espera, estamos guardando el evento ${
        this.eventsForm().get('title')?.value
      }`,
      allowOutsideClick: false,
      didOpen: () => Swal.showLoading(),
    });

    this._apiService
      .put<IEvent>(params)
      .pipe(
        takeUntil(this._destroy$),
        switchMap((event: IEvent) => {
          return this.uploadGuestQry(event.id!).length > 0
            ? forkJoin(this.uploadGuestQry(event.id!))
            : of(null);
        })
      )
      .subscribe({
        next: () => {
          Swal.fire({
            icon: 'success',
            text: 'Evento guardado.',
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              this.onModalClose.emit();
              this.refreshData.emit();
            }
          });
        },
        error: (err) => {
          Swal.fire({
            icon: 'success',
            text: `Error al guardar el evento: ${err.message}`,
            showConfirmButton: true,
            allowOutsideClick: false,
          });
          console.log(err);
        },
        complete: () => this.isSending.set(false),
      });
  }

  /**
   * Filter schools
   * @param event
   * @returns
   */
  public getSchools(event: Event) {
    const value = (event.target as HTMLInputElement).value;

    if (value.length <= 3) {
      this.filteredSchools.set([]);
      return;
    }

    const filteredData = [...this.mainSchools()]
      .filter((e) => e.NameTSchool?.toLocaleLowerCase().trim().includes(value))
      .splice(0, 4);

    this.filteredSchools.set(filteredData);
  }

  /**
   * Prepare get guest query
   * @param value
   * @returns
   */
  private guestQry(value: string): Observable<IUserUserApp[]> {
    const fetchParams = {
      path: 'ViewUserInfos',
      filter: {
        where: {
          or: [
            { email: { regexp: `/${value}/i` } },
            { Document: { regexp: `/${value}/i` } },
          ],
        },
        limit: 4,
      },
    };

    return this._apiService
      .get<IUserUserApp>(fetchParams)
      .pipe(takeUntil(this._destroy$));
  }

  /**
   * Prepare upload guest
   * @param eventId
   * @returns
   */
  private uploadGuestQry(eventId: number) {
    const params = (userId: number, id: number | undefined) => ({
      path: 'ScheduleEventUsers',
      data: {
        id, //If id is null, a new record will be created, otherwise it will only be updated.
        scheduleEventID: eventId,
        userID: userId,
      },
    });

    return this._selectedGuests().map((e) =>
      this._apiService
        .put<IEventUser>(params(e.id!, e.eventUserId))
        .pipe(takeUntil(this._destroy$))
    );
  }

  /**
   * Get value to search in db
   * @param event
   */
  public findGuest(event: Event) {
    const value = (event.target as HTMLInputElement).value
    this._guestSubject$.next(value);
  }

  /**
   * Add guests to the list
   * @param item
   */
  public selectGuest(value: string) {
    const getUserInfo = this._filteredGuests().find((e) => e.id === Number(value));
    let usersToAdd = [...this._selectedGuests()];

    const alreadyAdded = this._selectedGuests().some(
      (e) => e.id == Number(value)
    );

    if (!alreadyAdded && getUserInfo) 
      usersToAdd.push(getUserInfo);

    this._selectedGuests.set(usersToAdd);
  }

  /**
   * Clear username to display
   * @param e
   * @returns
   */
  private clearName(user: IUserUserApp) {
    return `${user.Name1} ${user.Name2 || ''} ${user.LastName1} ${
      user.LastName2 || ''
    }`.replace(/\s+/g, ' ');
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
