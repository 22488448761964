<div class="select">
  <div class="select-field" [ngClass]="combinedClasses()">
    <label class="field-label">{{ label() }}</label>
    <div class="input-field__content">
      <div class="decorator">
        <ng-content select="[start-decorator]" />
      </div>

      <div class="input-field__content-value" *ngIf="currentItem() !== undefined">
        {{ currentItem()?.label }}
      </div>
    </div>

    <div class="selector">
      <em class="icon-i-angle-right selector-icon" [ngClass]="{ 'selector-icon--active': isTriggered() }"></em>
    </div>
  </div>

  <ng-content select="ui-select-content" />
</div>